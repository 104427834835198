import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Cms from '../../entities/preferences/cms'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import configurationModule from './configuration';

interface CmsState extends ListState<Cms>{
    editModel: Cms;
    pageContent: String;
}
class CmsModule extends ListModule<CmsState, any, Cms>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<Cms>(),
        loading:false,
        editModel:new Cms(),
        pageContent: ''
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async get(context: ActionContext<CmsState, any>, payload: any) {
            context.state.loading = true;
            let params = {
                'ajax': 1,
                'action': 'getCmsPage',
                'link': payload.link
            };

            let reponse = await Ajax.get('?controller=index', { params: params }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            context.state.pageContent = reponse.data.page.content;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const cmsModule=new CmsModule();
export default cmsModule;