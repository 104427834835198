import Entity from '../entity'

export default class PaymentGateway extends Entity<number>{
    customer_card_id?: number;
    payment_method_id?: number;
    name: string;
    code: string;
    main: boolean;
    direct: boolean;
    installments?: number;
    brand?: string;
    card_number?: string;
    bank_name?: string;
    account?: string;
    cci?: string;
    number?: string;
    message?: string;
    discount?: number;
    
    amount: number;
}

