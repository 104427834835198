import Entity from '../entity'

export default class Wallet extends Entity<number>{
    customer_id: number;
    currency_id: number;
    year: number;
    month: number;
    total_bonus: number;
    total_earned: number;
    total_charged: number;
    balance: number;
    total_purchases: number;
}


