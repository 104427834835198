import Entity from '../entity'
import OrderDetail from './orderdetail';
import PaymentGateway from '../cashiers/paymentgateway';

export default class Order extends Entity<number>{
    reference: string;
    customer_id: number;
    currency_id: number;
    currency_sign: string;
    state_id: number;
    exchange_rate: number;
    total: number;
    total_paid: number;
    balance: number;
    points: number;
    sale_date: string;
    serie: string;
    number: string;
    notes: string;
    valid: boolean;
    details: Array<OrderDetail>;
    
    payments: Array<PaymentGateway>;
}


