import Entity from '../entity'

export default class Range extends Entity<number>{
    name: string;
    position: number;
    image: string;
    nodes: number;
    direct_range_id: number;
    pay_top: number;
    active: boolean;
    public: boolean;

    image_url: string;
}


