import Entity from '../entity'
export default class User extends Entity<number>{
    gender_id: number;
    sponsor_id: number;
    parent_id: number;
    document_type_id: number;
    address_id: number;
    username: string;
    avatar: string;
    password: string;
    document: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    bank_account: string;
    cci: string;
    birthdate: string;
    team: number;
    state_id: number;
    range_id: number;
    country_id: number;

    fullname: string;
    avatar_url: string;
    password2: string;
    auth_code: string;
    sponsor_name: string;
    city: string;
}