import Entity from '../entity'

export default class Page extends Entity<number>{
    title: string;
    content: string;
    version: number;
    with_versions: boolean;
    active: boolean;
}


