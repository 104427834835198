import Vue from 'vue';
import App from './App.vue';
import { router } from './router/index';
//import 'famfamfam-flags/dist/sprite/famfamfam-flags.css';
import './theme.scss';
import Ajax from './lib/ajax';
import Util from './lib/util';

import PushNotifications from './lib/notifications'; 
import vuetify from './lib/vuetify';
import VuetifyToast from 'vuetify-toast-snackbar'  
import VueSweetalert2 from 'vue-sweetalert2';
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'
import VueTheMask from 'vue-the-mask'
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueTheMask);
Vue.use(VuetifyToast); 
Vue.use(VueSweetalert2);
Vue.use(require('vue-shortkey'), { prevent: ['.excl-shortkey input', 'textarea'] });
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

import store from './store/index';
Vue.config.productionTip = false;
import { appRouters, otherRouters } from './router/router';
//import 'roboto-fontface/css/roboto/roboto-fontface.css'
//import 'font-awesome/css/font-awesome.css'
const moment = require('moment')
require('moment/locale/es')
Vue.use(require('vue-moment'), { moment })

Ajax.get('/api/customer/getConfigurations').then(data => {
    Util.abp = Util.extend(true, Util.abp, data.data.result);
}).finally(function () {    

    new Vue({
        vuetify,
        router: router,
        store: store,
        render: h => h(App),
        data: {
            currentPageName: '',
            $confirm: (title: string, message: string, options?: any) => { }
        },
        async mounted() {
            this.currentPageName = this.$route.name as string;            

            if (!!Util.abp.session.userId) {
                await this.$store.dispatch({
                    type: 'session/init'
                })

                PushNotifications.init(this);

                this.$store.commit('app/initCachepage');
                this.$store.commit('app/updateMenulist');
                this.$store.commit('order/initCache');
            }
        },
        created() {
            //let tagsList: Array<any> = [];

            //tagsList.push(...otherRouters.children);
            //this.$store.commit('app/setTagsList', tagsList);
        }
    }).$mount('#app')
})

