import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import WalletTransaction from '../../entities/cashiers/wallettransaction'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Customer from '@/store/entities/network/customer';
import PageResult from '@/store/entities/page-result';

interface WalletTransactionState extends ListState<WalletTransaction> {
    editModel: WalletTransaction;
    customer: Customer;
    reportList: Array<any>;
}
class WalletTransactionModule extends ListModule<WalletTransactionState, any, WalletTransaction>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 5,
        list: new Array<WalletTransaction>(),
        loading: false,
        editModel: new WalletTransaction(),
        customer: new Customer(),
        activeList: new Array<WalletTransaction>(),
        reportList: new Array<any>(),
        path: 'customer/wallet-transactions'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<WalletTransactionState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/' + (context.state as any).path, { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            let page = reponse.data.result as PageResult<WalletTransaction>;
            context.state.totalCount = page.total;
            context.state.list = page.data;

            context.state.list.forEach((item, index) => {
                let type = Util.abp.list.getItem(item.type_id);
                item['color'] = (type.extra == '1' ? '#4CAF50' : '#F44336');
            });

            return context.state.list;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations 
        new(state: WalletTransactionState, model: WalletTransaction) {
            let type = Util.abp.list.get('T_MOV', 'R');
            state.editModel = Object.assign({});
            state.editModel.currency_id = Util.abp.setting.get('DEFAULT_CURRENCY');
            state.editModel.customer_id = Util.abp.session.userId;
            state.editModel.origin_id = Util.abp.session.userId;
            state.editModel.type_id = type.id;
            state.editModel.process_date = Util.abp.clock.today();
            state.editModel.sign = parseInt(type.extra);
            state.editModel.amount = 0;

        },
        edit(state: WalletTransactionState, model: WalletTransaction) {
            state.editModel = model;
        },
        setCustomer(state: WalletTransactionState, model: Customer) {
            state.customer = model;
        }
    });
}
const wallettransactionModule = new WalletTransactionModule();
export default wallettransactionModule;