import Entity from '../entity'

export default class Customer extends Entity<number>{
    gender_id: number;
    sponsor_id: number;
    parent_id: number;
    document_type_id: number;
    address_id: number;
    username: string;
    avatar: string;
    document: string;
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
    bank: string;
    bank_account: string;
    wallet_address: string;
    tendyc_code: string;
    birthdate: string;
    team: number;
    state_id: number;
    range_id: number;
    country_id: number;

    fullname: string;
    avatar_url: string;
}


