import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Range from '../../entities/catalogs/range'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface RangeState extends ListState<Range> {
    editModel: Range;
    activeList: Array<Range>;
}
class RangeModule extends ListModule<RangeState, any, Range>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Range>(),
        loading: false,
        editModel: new Range(),
        activeList: new Array<Range>(),
        path: 'customer/ranges'
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const rangeModule = new RangeModule();
export default rangeModule;