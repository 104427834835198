import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Wallet from '../../entities/cashiers/wallet'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface WalletState extends ListState<Wallet>{
    editModel: Wallet;
}
class WalletModule extends ListModule<WalletState,any,Wallet>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 5,
        list: new Array<Wallet>(),
        loading:false,
        editModel: new Wallet(),
        path: 'customer/wallets'      
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getWallet(context: ActionContext<WalletState, any>, payload: any) {
            if (context.state.loading) return context.state.editModel;              
            context.state.loading = true;
            let reponse = await Ajax.get('/api/customer/get-wallet', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.editModel = reponse.data.result;
            return context.state.editModel;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const walletModule=new WalletModule();
export default walletModule;