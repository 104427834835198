import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import BonusCalculation from '../../entities/catalogs/bonuscalculation'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface BonusCalculationState extends ListState<BonusCalculation> {
    editModel: BonusCalculation;
}
class BonusCalculationModule extends ListModule<BonusCalculationState, any, BonusCalculation>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<BonusCalculation>(),
        loading: false,
        editModel: new BonusCalculation(),
        path: 'customer/bonus-calculations',
    }
    actions = {
        async getAll(context: ActionContext<BonusCalculationState, any>, payload: any) {
            if (context.state.loading) return context.state.list;
            context.state.loading = true;
            let reponse = await Ajax.get('/api/customer/bonus-calculations', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });
            context.state.loading = false;
            context.state.totalCount = reponse.data.result.length;
            context.state.list = reponse.data.result;
            return context.state.list;
        },
    };
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const bonuscalculationModule = new BonusCalculationModule();
export default bonuscalculationModule;