import Entity from '../entity'

export default class BonusCalculation extends Entity<number>{
    bonus_id: number;
    bonus_detail_id: number;
    sale_id: number;
    customer_id: number;
    year: number;
    month: number;
    amount: number;
    process_date: string;
    valid: boolean;
}


