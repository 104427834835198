import { Store, Module, ActionContext } from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Node from '../../entities/network/node'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface NodeState extends ListState<Node> {
    editModel: Node;
    network: any;
    parents: Array<any>;
    maxLevel: number;
}
class NodeModule extends ListModule<NodeState, any, Node>{
    state = {
        totalCount: 0,
        currentPage: 1,
        pageSize: 10,
        list: new Array<Node>(),
        network: {},
        loading: false,
        editModel: new Node(),
        activeList: new Array<Node>(),
        parents: new Array<any>(),
        maxLevel: 1,
        path: 'nodes'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getNetwork(context: ActionContext<NodeState, any>, payload: any) {
            context.state.loading = true;
            let response = await Ajax.get('/api/customer/get-network', { params: payload.data }).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            context.state.loading = false;
            context.state.editModel = response.data.result.node;
            context.state.network = response.data.result.network;
            context.state.parents = response.data.result.parents;
            context.state.maxLevel = response.data.result.max_level;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
    });
}
const nodeModule = new NodeModule();
export default nodeModule;