import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import OrderDetail from '../../entities/sales/orderdetail'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import Product from '@/store/entities/catalogs/product';
import Order from '@/store/entities/sales/order';

interface OrderDetailState extends ListState<OrderDetail>{
    editModel:OrderDetail;    
    order:number;    
}
class OrderDetailModule extends ListModule<OrderDetailState,any,OrderDetail>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<OrderDetail>(),
        loading:false,
        editModel: new OrderDetail(), 
        order: 0,
        path: 'orders'         
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async getAll(context: ActionContext<OrderDetailState, any>, payload: any) {
            context.state.list = [];
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: OrderDetailState, model: Order) {
            state.editModel = Object.assign({});
            if (model != undefined && state.list.length != model.details.length) {
                state.order = model.id;
                state.list = model.details;
            } 
        },
        reset(state: OrderDetailState) {
            state.editModel = Object.assign({});
            state.order = 0;
            state.list = [];
        },
        add(state: OrderDetailState, product: Product) {
            let exist = state.list.filter((detail: any) => {
                return detail.product_id == product.id;
            });

            if (exist.length > 0) {
                if (!exist[0].licence) {
                    exist[0].quantity++;
                    exist[0].total = exist[0].quantity * exist[0].price;
                }
            } else {
                let detail = new OrderDetail();
                detail.product_id = product.id;
                detail.product_name = product.name;
                detail.quantity = product.quantity;
                detail.price = product.price;
                detail.discount = 0;
                detail.total = (product.price * detail.quantity);
                detail.points = product.points;
                detail.product = product;
                detail.licence = (product.product_type_id == Util.abp.list.get('T_PROD','L').id);

                if (product.discount != undefined && product.discount > 0) {
                    detail.discount = (product.discount / 100) * detail.total;
                    detail.total = detail.total - detail.discount;
                }

                state.list.push(detail);
            }
        },
        update(state: OrderDetailState, detail: OrderDetail) {
            let exist = state.list.filter((d: any) => {
                return d.product_id == detail.product_id;
            });

            if (exist.length > 0) {
                exist[0].quantity = parseInt(String(detail.quantity));
                exist[0].total = exist[0].price * exist[0].quantity;

                detail = exist[0]; 
            } 
        }, 
        delete(state: OrderDetailState, index: number) {
            state.list.splice(index, 1);
        },      
    });
}
const orderdetailModule=new OrderDetailModule();
export default orderdetailModule;