import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import PaymentGateway from '../../entities/cashiers/paymentgateway'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'

interface PaymentGatewayState extends ListState<PaymentGateway>{
    editModel:PaymentGateway;
    publicKey: string;
    lastCard: any;
    cardAdded: boolean;
}
class PaymentGatewayModule extends ListModule<PaymentGatewayState,any,PaymentGateway>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 10,
        list: new Array<PaymentGateway>(),
        loading:false,
        cardAdded:false,
        editModel: new PaymentGateway(),
        publicKey: '',
        path: 'customer/cards'     
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async card(context: ActionContext<PaymentGatewayState, any>, payload: any) {
            //context.state.loading = true;
            //context.state.cardAdded = false;

            //let response = await Ajax.post('/api/customer/cards', payload.data).catch(e => {
            //    context.state.loading = false;
            //    return Promise.reject(e);
            //});

            //context.state.lastCard = response.data.result;
            //context.state.loading = false;
            //context.state.cardAdded = true;

            //if (response.data.result != undefined) {
            //    context.state.list.forEach(meth => {
            //        meth.main = false;
            //    });
            //    let met: PaymentGateway;

            //    let culqi = Util.abp.list.get('GATEWAY','CULQI');
            //    let card = response.data.result;
            //    met = {
            //        id: card.gateway_id,
            //        customer_card_id: card.id,
            //        code: culqi.code,
            //        brand: card.card_brand.toLowerCase(),
            //        direct: culqi.extra == '1',
            //        main: true,
            //        name: culqi.name,
            //        payment_method_id: card.payment_method_id,
            //        installments: 1,
            //        amount: 0
            //    };

            //    context.state.list.push(met);
            //}

            //return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        init(state: PaymentGatewayState, methods: Array<any>) {
            state.list = [];

            //state.publicKey = Util.abp.setting.get('CULQI_PUBLIC_KEY');
            //(window as any).Culqi.publicKey = Util.abp.setting.get('CULQI_PUBLIC_KEY');
            
            let list = Util.abp.list.getList('GATEWAY');
            list.forEach(item => {
                let meth = methods.filter(meth => { return meth.id == Util.abp.setting.get(item.code + '_PAYMENT_METHOD') })[0];
                if (Util.abp.setting.get(item.code + '_ENABLED') == 1) {
                    state.list.push({
                        id: item.id,
                        code: item.code,
                        brand: item.code.toLowerCase(),
                        direct: item.extra == '1',
                        main: item.value == 1,
                        name: item.name,
                        payment_method_id: (meth != undefined ? meth.id : null),
                        discount: (meth != undefined ? meth.discount : null),
                        message: Util.abp.setting.get(item.code + '_MESSAGE'),
                        bank_name: Util.abp.setting.get(item.code + '_NAME'),
                        account: Util.abp.setting.get(item.code + '_ACCOUNT'),
                        cci: Util.abp.setting.get(item.code + '_CCI'),
                        number: Util.abp.setting.get(item.code + '_NUMBER'),
                        amount: 0
                    });
                }
            });

            //if (Util.abp.setting.get('CULQI_ENABLED') == 1) {
            //    state.list.push({
            //        code: 'CULQI',
            //        name: 'Pago con CULQI',
            //        sandbox: Util.abp.setting.get('CULQI_SANBOX'),
            //        multi: Util.abp.setting.get('CULQI_MULTIPAYMENT'),
            //    });
            //}
        },
    });
}
const paymentgatewayModule=new PaymentGatewayModule();
export default paymentgatewayModule;