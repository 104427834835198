import Entity from '../entity'

export default class Cms extends Entity<number>{
    meta_title: string;
    meta_description: string;
    meta_keywords: string;
    content: string;
    link_rewrite: string;
    id_cms_category: number;
}


