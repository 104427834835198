import Entity from '../entity'

export default class PaymentMethod extends Entity<number>{
    name: string;
    type: string;
    bank_account_id: number;
    for_sales: boolean;
    for_purchases: boolean;
    active: boolean;
    require_document: boolean;
}

