declare global {
    interface RouterMeta {
        title: string;
    }
    interface Router {
        path: string;
        name: string;
        icon?: string;
        permission?: string;
        meta?: RouterMeta;
        component: any;
        children?: Array<Router>;
    }
    interface System {
        import(request: string): Promise<any>
    }
    var System: System
}
import home from '../views/home/home.vue'
import main from '../views/main.vue'
import Ajax from '../lib/ajax';


export const otherRouters: Router = {
    path: '/',
    name: 'main',
    permission: '',
    meta: { title: '' },
    component: main,
    children: [
        { path: '/', meta: { title: 'Inicio' }, name: 'home', component: () => import('../views/home/home.vue') },
        { path: 'U/:ref', meta: { title: 'Registro por Referido' }, name: 'register', component: () => import('../views/home/home.vue') },
        { path: 'page', meta: { title: 'Pagina' }, name: 'page', component: () => import('../views/home/page.vue') },
        { path: 'contact', meta: { title: 'Contactanos' }, name: 'contact', component: () => import('../views/home/contact.vue') },
        { path: 'tienda', meta: { title: 'Tienda' }, name: 'store', component: () => import('../views/catalogs/company/company.vue') },
        { path: 'product', meta: { title: 'Producto' }, name: 'product', component: () => import('../views/catalogs/product/product.vue') },
        { path: 'account/dashboard', meta: { title: 'Dashboard' }, name: 'dashboard', permission: '1', component: () => import('../views/account/home/dashboard.vue') },
        { path: 'account/perfil', meta: { title: 'Cuenta' }, name: 'profile', permission: '1', component: () => import('../views/account/profile/edit-profile.vue') },
        //{ path: 'account/address', meta: { title: 'Direcciones' }, name: 'address', permission: '1', component: () => import('../views/account/profile/addresses.vue') },
        //{ path: 'account/promo-code', meta: { title: 'Promociones' }, name: 'promo', permission: '1', component: () => import('../views/account/order/promo-codes.vue') },
        { path: 'account/tarjetas', meta: { title: 'Tarjetas de Credito' }, name: 'card', permission: '1', component: () => import('../views/account/card/cards.vue') },
        { path: 'account/pedidos', meta: { title: 'Pedidos' }, name: 'order', permission: '1', component: () => import('../views/account/order/orders.vue') },
        { path: 'account/pedidos/:id', meta: { title: 'Detalle de Pedido' }, name: 'orderdetail', permission: '1', component: () => import('../views/account/order/order-detail.vue') },
        { path: 'account/red', meta: { title: 'Red' }, name: 'network', permission: '1', component: () => import('../views/account/network/network.vue') },
        { path: 'cart/checkout', meta: { title: 'Checkout' }, name: 'checkout', permission: '1', component: () => import('../views/sales/cart/checkout.vue') }, 
    ]
}
export const appRouters: Array<Router> = [];

export const routers = [
    ...appRouters,
    otherRouters
];
