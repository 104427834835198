import Entity from '../entity'

export default class WalletTransaction extends Entity<number>{
    wallet_id: number;
    customer_id: number;
    currency_id: number;
    sale_id: number;
    type_id: number;
    origin_id: number;
    bonus_id: number;
    state_id: number;
    sign: number;
    amount: number;
    amount_real: number;
    description: string;
    process_date: string;
}


