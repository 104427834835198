import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);
import app from './modules/app'
import session from './modules/session'
import user from './modules/account/user' 
import address from './modules/account/address'
import dashboard from './modules/account/dashboard'

import company from './modules/catalogs/company'
import product from './modules/catalogs/product'
import range from './modules/catalogs/range'
import bonuscalculation from './modules/catalogs/bonuscalculation'

import country from './modules/preferences/country'
import city from './modules/preferences/city'
import generaltype from './modules/preferences/generaltype'
import generaltypevalue from './modules/preferences/generaltypevalue'
import configuration from './modules/preferences/configuration'
import notification from './modules/preferences/notification'
import conversation from './modules/preferences/conversation'
import cms from './modules/preferences/cms'
import node from './modules/network/node'
import customer from './modules/network/customer'
import documenttype from './modules/network/documenttype'
import page from './modules/cms/page'

import paymentgateway from './modules/cashiers/paymentgateway'
import paymentmethod from './modules/cashiers/paymentmethod'
import wallet from './modules/cashiers/wallet'
import wallettransaction from './modules/cashiers/wallettransaction'

import order from './modules/sales/order'
import orderdetail from './modules/sales/orderdetail'

const store = new Vuex.Store({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {

    },
    modules: {
        app,
        session,
        user,
        address,
        dashboard,

        company,
        product,
        range,
        bonuscalculation,

        country,
        city,
        configuration,
        generaltype,
        generaltypevalue,
        notification,
        conversation,
        cms,

        paymentgateway,
        paymentmethod,
        wallet,
        wallettransaction,
        node,
        customer,
        documenttype,
        page,

        order,
        orderdetail,
    }
});

export default store;