import Entity from '../entity'

export default class Node extends Entity<number>{
    customer_id: number;
    sponsor_id: number;
    parent_id: number;
    code: string;
    team: number;
    level: number;
    network_count: number;
    active: boolean;
}


