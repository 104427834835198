import {Store,Module,ActionContext} from 'vuex'
import ListModule from '../list-module'
import ListState from '../list-state'
import Order from '../../entities/sales/order'
import Ajax from '../../../lib/ajax'
import Util from '../../../lib/util'
import OrderDetail from '@/store/entities/sales/orderdetail';
import util from '../../../lib/util';

interface OrderState extends ListState<Order>{
    editModel: Order;
    viewModel: Order;
    totalProducts: number;
    uploading: boolean;
}
class OrderModule extends ListModule<OrderState,any,Order>{
    state={
        totalCount:0,
        currentPage:1,
        pageSize: 5,
        list: new Array<Order>(),
        loading:false,
        editModel: new Order(),
        viewModel: new Order(),
        uploading: false,
        totalProducts: 0,
        path: 'customer/sales'      
    }
    actions = Util.extend(true, this.base_actions, {
        //extra actions
        async create(context: ActionContext<OrderState, any>, payload: any) {
            context.state.loading = true;

            let reponse = await Ajax.post('api/customer/sales', payload.data).catch(e => {
                context.state.loading = false;
                return Promise.reject(e);
            });

            if (reponse.data.result) {
                context.state.editModel = Object.assign({});
                context.state.editModel.details = new Array<OrderDetail>();
                context.state.totalProducts = 0;
                localStorage.order = JSON.stringify(context.state.editModel);
            }

            context.state.loading = false;
            return reponse.data.result;
        },
        async uploadVoucher(context: ActionContext<OrderState, any>, payload: any) {
            context.state.uploading = true;
            let response = await Ajax.post('/api/customer/upload-voucher',
                payload.data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).catch(e => {
                context.state.uploading = false;
                return Promise.reject(e);
            });
            
            context.state.uploading = false;
            return response.data.result;
        },
    });
    mutations = Util.extend(true, this.base_mutations, {
        //extra mutations
        initCache(state: OrderState, model: Order) {
            if (localStorage.order != null) {
                let order = JSON.parse(localStorage.order);
                state.editModel = Util.extend(true, {}, order); 
            } else {
                state.editModel = Object.assign({});
                state.editModel.details = new Array<OrderDetail>();
            }

            if (state.editModel.details == undefined) state.editModel.details = [];

            state.totalProducts = 0;
            state.editModel.details.forEach(item => {
                state.totalProducts += item.quantity;
            });

            localStorage.order = JSON.stringify(state.editModel);
        },
        updateAmount(state: OrderState, details: Array<OrderDetail>) {
            state.editModel.details = details;
            state.editModel.total = 0;
            state.editModel.points = 0;

            details.forEach((item, index) => {
                state.editModel.total += item.total;
                state.editModel.points += item.points * item.quantity;
            });
                       
            state.totalProducts = 0;
            state.editModel.details.forEach(item => {
                state.totalProducts += item.quantity;
            });

            localStorage.order = JSON.stringify(state.editModel);
        },
        setPayment(state: OrderState, items: any) {
            state.editModel.payments = items;            
            localStorage.order = JSON.stringify(state.editModel);
        },
        view(state: OrderState, model: Order) {
            state.viewModel = model;
        },
    });
}
const orderModule=new OrderModule();
export default orderModule;